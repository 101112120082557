@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.roboto-mono {
  font-family: "Roboto Mono", monospace;
  font-optical-sizing: auto;
  font-style: normal;
}
@font-face {
  font-family: "GL-Nummernschild-Mtl";
  font-style: normal;
  font-weight: 400;
  src: url("../public/fonts/GL-Nummernschild-Mtl.ttf") format("truetype"); /* format "ttf" o'rniga "truetype" bo'lishi kerak */
}

.GL-Nummernschild-Mtl {
  font-family: "GL-Nummernschild-Mtl", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logo {
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
  /* border-right: 1px solid #ececec; */
}
.logo p {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  color: #14151a;
}
aside {
  min-width: 284px !important;
}
.ant-table {
  border-radius: 0 !important;
}

.ant-table-cell {
  color: rgba(15, 19, 36, 0.6) !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.ant-menu-item {
  /* padding-left: 8px !important; */
}
.ant-upload,
.ant-upload-select {
  border-color: #26bd6c !important;
}

.color-box {
  box-shadow: -0.2px 0.4px 1.2px 0px rgba(255, 255, 255, 0.65) inset,
    0.4px -0.4px 1.2px 0px rgba(0, 0, 0, 0.25) inset;
}

.price-card {
  background: linear-gradient(
    275deg,
    rgba(255, 255, 255, 0.66) 0.44%,
    #fff 41.29%,
    #fff 69.53%,
    rgba(255, 255, 255, 0.36) 103.12%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ant-upload-wrapper {
  width: 100% !important;
  height: 100% !important;
}
